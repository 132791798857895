define('m0601/controllers/schwingungsnachweis', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {

    i18n: Ember['default'].inject.service(),

    debug: true,

    middlePart: false,
    bsnachweis: false,
    bsnDisabled: true,
    bsnCbDisabled: true,
    deckenfeldbreite: "",
    biegefestigkeitQuer: "",
    daempfungsmass: "",
    iterationen: "",

    divWInst: "",
    divWFin: "",
    divWFinNet: "",

    grenzwertVerformungen: "",

    validations: {
      grenzwertVerformungen: {
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 10000
        }
      },
      deckenfeldbreite: {
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 999.9
        }
      },
      biegefestigkeitQuer: {
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 999999999
        }
      },
      daempfungsmass: {
        numericality: {
          greaterThanOrEqualTo: 0.01,
          lessThanOrEqualTo: 0.1
        }
      },
      divWInst: {
        numericality: {
          greaterThanOrEqualTo: 1,
          lessThanOrEqualTo: 999.9
        }
      },
      divWFin: {
        numericality: {
          greaterThanOrEqualTo: 1,
          lessThanOrEqualTo: 999.9
        }
      },
      divWFinNet: {
        numericality: {
          greaterThanOrEqualTo: 1,
          lessThanOrEqualTo: 999.9
        }
      },
      iterationen: {
        numericality: {
          greaterThanOrEqualTo: 1,
          lessThanOrEqualTo: 25
        }
      }
    },

    ttGrenzwertVerformungen: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " > 0";
    }),
    ttDeckenfeldbreite: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0 - 999.9 m";
    }),
    ttBiegefestigkeitQuer: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0 - 999999999 kNcm²/m";
    }),
    ttDaempfungsmass: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0.01 - 0.1";
    }),
    ttDivWInst: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 1 - 999.9";
    }),
    ttDivWFin: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 1 - 999.9";
    }),
    ttDivWFinNet: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 1 - 999.9";
    }),
    ttIterationen: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 5 - 25";
    }),

    schwingungsnachweise: [{
      //   name: "Kein Schwingungsnachweis führen",
      //   value: 0
      // }, {
      //   name: "Begrenzung der Verformungen",
      //   value: 1
      // }, {
      //   name: "Genormtes Nachweisverfahren",
      //   value: 2
      // }, {
      //   name: "Empfehlung nach HAMM / RICHTER",
      //   value: 3
    }],

    schwingungsnachweis: 0,

    begrenzungenVerformungen: [{
      //   name: "ständigen Einwirkungen",
      //   value: "1a"
      // }, {
      //   name: "quasi-ständigen Einwirkungen",
      //   value: "1b"
    }],

    begrenzungVerformung: "1a",

    nweisverfahren: [{
      //   name: "nach DIN 1052-2004-12",
      //   value: "2a"
      // }, {
      //   name: "nach EN 1995-1-1/NA",
      //   value: "2b"
    }],

    nachweisverfahren: "2a",

    deckenNEinheiten: [{
      //   name: "Decken zwischen unterschiedlichen Nutzungseinheiten",
      //   value: "3a"
      // }, {
      //   name: "Decken innerhalb einer Nutzungseinheit",
      //   value: "3b"
    }],

    deckenNutzungseinheiten: "3a",

    brandschutznachweise: [{
      //   name: "R0",
      //   value: 0
      // }, {
      //   name: "R30",
      //   value: 1
      // }, {
      //   name: "R60",
      //   value: 2
      // }, {
      //   name: "R90",
      //   value: 3
      // }, {
      //   name: "R120",
      //   value: 4
    }],

    brandschutznachweis: 1,

    init: function init() {
      this._super();

      this.set('divWInst', "300");
      this.set('divWFin', "200");
      this.set('divWFinNet', "200");
      this.set('grenzwertVerformungen', "0");
      this.set('iterationen', "10");
      this.set('deckenfeldbreite', "0.00");
      this.set('biegefestigkeitQuer', "0");
      this.set('daempfungsmass', "0.01");

      this.setSelectFieldsContent();
    },

    setValues: function setValues(values) {

      var self = this;

      if (values.VarNachweisSchwingung == "0") {
        self.set('schwingungsnachweis', 0);
      } else if (values.VarNachweisSchwingung == "1a") {
        self.set('schwingungsnachweis', 1);
        self.set('begrenzungVerformung', "1a");
      } else if (values.VarNachweisSchwingung == "1b") {
        self.set('schwingungsnachweis', 1);
        self.set('begrenzungVerformung', "1b");
      } else if (values.VarNachweisSchwingung == "2a") {
        self.set('schwingungsnachweis', 2);
        self.set('nachweisverfahren', "2a");
      } else if (values.VarNachweisSchwingung == "2b") {
        self.set('schwingungsnachweis', 2);
        self.set('nachweisverfahren', "2b");
      } else if (values.VarNachweisSchwingung == "3a") {
        self.set('schwingungsnachweis', 3);
        self.set('deckenNutzungseinheiten', "3a");
      } else if (values.VarNachweisSchwingung == "3b") {
        self.set('schwingungsnachweis', 3);
        self.set('deckenNutzungseinheiten', "3b");
      }

      self.set('grenzwertVerformungen', parseFloat(values.DIV_w_Schwingung).toFixed(1));
      self.set('deckenfeldbreite', parseFloat(values.b_Deckenfeld).toFixed(2));
      self.set('biegefestigkeitQuer', parseFloat(values.EI_quer).toFixed(1));
      self.set('daempfungsmass', parseFloat(values.Daempfungsmass).toFixed(3));
      self.set('divWInst', parseFloat(values.DIV_w_inst).toFixed(1));
      self.set('divWFin', parseFloat(values.DIV_w_fin).toFixed(1));
      self.set('divWFinNet', parseFloat(values.DIV_w_fin_net).toFixed(1));

      self.set('bsnachweis', values.brandschutznachweisFuehren);
      self.set('brandschutznachweis', parseInt(values.brandschutznachweis));

      self.set('abstuetzungWaehrendMontage', values.abstuetzungWaehrendMontage);
      self.set('anzahlAbstuetzpunkte', parseInt(values.anzahlAbstuetzpunkte));

      self.set('iterationen', values.MaxAnzahlIteration);

      self.send('validation', parseFloat(self.get('iterationen')).toFixed(0), { target: { name: "iterationen" } });
    },

    setSelectFieldsContent: function setSelectFieldsContent() {
      var self = this;
      self.setschwingungsnachweise();
      self.setbegrenzungenVerformungen();
      self.setnweisverfahren();
      self.setdeckenNEinheiten();
      self.setbrandschutznachweise();
    },

    setschwingungsnachweise: function setschwingungsnachweise() {
      var self = this;
      var indices = schwingungnachweiseDB.auswahlfelder.findBy('bezeichnung', 'schwingungsnachweise').indices;
      self.set('schwingungsnachweise', self.getSelectFieldContent('schwingungsnachweise', indices));
    },

    setbegrenzungenVerformungen: function setbegrenzungenVerformungen() {
      var self = this;
      var indices = schwingungnachweiseDB.auswahlfelder.findBy('bezeichnung', 'begrenzungenVerformungen').indices;
      self.set('begrenzungenVerformungen', self.getSelectFieldContent('begrenzungenVerformungen', indices));
    },

    setnweisverfahren: function setnweisverfahren() {
      var self = this;
      var indices = schwingungnachweiseDB.auswahlfelder.findBy('bezeichnung', 'nweisverfahren').indices;
      self.set('nweisverfahren', self.getSelectFieldContent('nweisverfahren', indices));
    },

    setdeckenNEinheiten: function setdeckenNEinheiten() {
      var self = this;
      var indices = schwingungnachweiseDB.auswahlfelder.findBy('bezeichnung', 'deckenNEinheiten').indices;
      self.set('deckenNEinheiten', self.getSelectFieldContent('deckenNEinheiten', indices));
    },

    setbrandschutznachweise: function setbrandschutznachweise() {
      var self = this;
      var indices = schwingungnachweiseDB.auswahlfelder.findBy('bezeichnung', 'brandschutznachweise').indices;
      self.set('brandschutznachweise', self.getSelectFieldContent('brandschutznachweise', indices));
    },

    getSelectFieldContent: function getSelectFieldContent(db, indexes) {
      var self = this;
      var tmp = [];

      var dbContent = schwingungnachweiseDB.auswahlfelder.findBy('bezeichnung', db).werte;

      for (var i = 0; i < indexes.length; i++) {
        for (var k = 0; k < dbContent.length; k++) {

          if (dbContent[k].id === indexes[i]) {
            // console.log('dbContent[k].id: '+dbContent[k].id+ '==? indexes[i]: '+indexes[i]);
            // console.log('dbContent[k].name: '+dbContent[k].name);
            // console.log(this.get('i18n').t(dbContent[k].name).toString());
            tmp.push({ id: dbContent[k].id, name: this.get('i18n').t(dbContent[k].name).toString() });
          }
        }
      }

      // console.log('tmp');
      // console.log(tmp);

      return tmp;
    },

    watchSchwingungsnachweis: (function () {

      var self = this;
      var schwingungsnachweisdata = self.controllerFor('application').get('model').schwingungsnachweis.objectAt(0);

      if (self.get('schwingungsnachweis') == 0) {
        self.set('bv', false);
        self.set('gn', false);
        self.set('hr', false);
        self.set('middlePart', false);
        schwingungsnachweisdata.set('VarNachweisSchwingung', '0');
      } else if (self.get('schwingungsnachweis') == 1) {
        self.set('bv', true);
        self.set('gn', false);
        self.set('hr', false);
        self.set('middlePart', false);
        console.log(self.get('begrenzungVerformung'));
        schwingungsnachweisdata.set('VarNachweisSchwingung', self.get('begrenzungVerformung'));
      } else if (self.get('schwingungsnachweis') == 2) {
        self.set('bv', false);
        self.set('gn', true);
        self.set('hr', false);
        self.set('middlePart', true);
        console.log(self.get('nachweisverfahren'));
        schwingungsnachweisdata.set('VarNachweisSchwingung', self.get('nachweisverfahren'));
      } else if (self.get('schwingungsnachweis') == 3) {
        self.set('bv', false);
        self.set('gn', false);
        self.set('hr', true);
        self.set('middlePart', true);
        console.log(self.get('deckenNutzungseinheiten'));
        schwingungsnachweisdata.set('VarNachweisSchwingung', self.get('deckenNutzungseinheiten'));
      }
    }).observes('schwingungsnachweis', 'begrenzungVerformung', 'nachweisverfahren', 'deckenNutzungseinheiten'),

    watchBrandschutznachweis: (function () {
      var self = this;
      var schwingungsnachweisdata = self.controllerFor('application').get('model').schwingungsnachweis.objectAt(0);

      if (self.get('bsnachweis')) {
        self.set('bsnDisabled', false);
        schwingungsnachweisdata.set('brandschutznachweisFuehren', true);
      } else {
        self.set('bsnDisabled', true);
        schwingungsnachweisdata.set('brandschutznachweisFuehren', false);
      }

      schwingungsnachweisdata.set('brandschutznachweis', self.get('brandschutznachweis'));
    }).observes('bsnachweis', 'brandschutznachweis'),

    azahlAbstuetzpunkte: [{
      name: "1",
      value: 1
    }, {
      name: "2",
      value: 2
    }, {
      name: "3",
      value: 3
    }],

    anzahlAbstuetzpunkte: 1,
    abstuetzungWaehrendMontage: false,
    aapDisabled: true,

    watchAbstuetzung: (function () {
      var self = this;
      var schwingungsnachweisdata = self.controllerFor('application').get('model').schwingungsnachweis.objectAt(0);

      if (self.get('abstuetzungWaehrendMontage')) {
        self.set('aapDisabled', false);
        schwingungsnachweisdata.set('abstuetzungWaehrendMontage', true);
      } else {
        self.set('aapDisabled', true);
        schwingungsnachweisdata.set('abstuetzungWaehrendMontage', false);
      }

      schwingungsnachweisdata.set('anzahlAbstuetzpunkte', self.get('anzahlAbstuetzpunkte'));
    }).observes('abstuetzungWaehrendMontage', 'anzahlAbstuetzpunkte'),

    watchNumericalInputs: (function () {

      if (!this.openFromFile) {

        if (this.get('divWInst').indexOf(",") !== -1) {
          this.set('divWInst', this.get('divWInst').replace(",", "."));
        }

        if (this.get('divWFin').indexOf(",") !== -1) {
          this.set('divWFin', this.get('divWFin').replace(",", "."));
        }

        if (this.get('divWFinNet').indexOf(",") !== -1) {
          this.set('divWFinNet', this.get('divWFinNet').replace(",", "."));
        }

        if (this.get('grenzwertVerformungen').indexOf(",") !== -1) {
          this.set('grenzwertVerformungen', this.get('grenzwertVerformungen').replace(",", "."));
        }

        if (this.get('iterationen').indexOf(",") !== -1) {
          this.set('iterationen', this.get('iterationen').replace(",", "."));
        }

        if (this.get('deckenfeldbreite').indexOf(",") !== -1) {
          this.set('deckenfeldbreite', this.get('deckenfeldbreite').replace(",", "."));
        }

        if (this.get('biegefestigkeitQuer').indexOf(",") !== -1) {
          this.set('biegefestigkeitQuer', this.get('biegefestigkeitQuer').replace(",", "."));
        }

        if (this.get('daempfungsmass').indexOf(",") !== -1) {
          this.set('daempfungsmass', this.get('daempfungsmass').replace(",", "."));
        }
      }
    }).observes('daempfungsmass', 'divWInst', 'divWFin', 'divWFinNet', 'grenzwertVerformungen', 'iterationen', 'deckenfeldbreite', 'biegefestigkeitQuer'),

    actions: {

      validation: function validation(value, event) {

        var self = this;

        if (!self.openFromFile) {

          // this.setX3D(value, event);

        }

        self.validate().then(function () {
          // all validati0ons pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {
            self.set('displayErrors', false);
            application.set('schwingungsnachweisInvalid', false);

            var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            applicationdata.set('treeLoaded', false);
            applicationdata.set('initialized', false);
            applicationdata.set('pdfErstellt', false);
            applicationdata.set('dxfErstellt', false);

            var downloadcenter = self.controllerFor('downloadcenter');
            downloadcenter.set('pdfErstellt', false);
            downloadcenter.set('dxfErstellt', false);

            var schwingungsnachweisdata = self.controllerFor('application').get('model').schwingungsnachweis.objectAt(0);

            schwingungsnachweisdata.set('DIV_w_Schwingung', self.get('grenzwertVerformungen'));
            schwingungsnachweisdata.set('b_Deckenfeld', self.get('deckenfeldbreite'));
            schwingungsnachweisdata.set('EI_quer', self.get('biegefestigkeitQuer'));
            schwingungsnachweisdata.set('Daempfungsmass', self.get('daempfungsmass'));
            schwingungsnachweisdata.set('MaxAnzahlIteration', self.get('iterationen'));

            schwingungsnachweisdata.set('DIV_w_inst', self.get('divWInst'));
            schwingungsnachweisdata.set('DIV_w_fin', self.get('divWFin'));
            schwingungsnachweisdata.set('DIV_w_fin_net', self.get('divWFinNet'));
          } else {
            self.set('displayErrors', true);
            application.set('schwingungsnachweisInvalid', true);
          }
        });
      },

      proceedInput: function proceedInput(event) {

        var self = this;

        //var ledText = self.ledarray[self.get('led')].name;
        //var nklText = self.nklarray[self.get('nkl')].name;

        var validation = self.get('isValid');

        // if (validation === true) {
        //
        //   var lasteinwirkung = self.store.createRecord('lasteinwirkung', {
        //
        //     KLED_ID: self.get('led'),
        //     LED_Text: ledText,
        //     NKL_ID: self.get('nkl'),
        //     NKL_Text: nklText,
        //     V_d: self.get('bmlast').replace(",", ".")
        //
        //   });
        // }

        self.validate().then(function () {
          // all validations pass
          this.transitionToRoute('verbindungsmittel');
        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {

            var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            // applicationdata.set('treeLoaded', false);
            // applicationdata.set('initialized', false);
            // applicationdata.set('pdfErstellt', false);
            // applicationdata.set('dxfErstellt', false);

            application.set('verbindungsmittelInvalid', true);

            self.set('displayErrors', false);
            application.set('lasteinwirkungInvalid', false);

            //var applicationdata = application.model.objectAt(0);

            //universal
            var applicationdata = application.get('model').application.objectAt(0);
            var applicationdataJSON = JSON.stringify(applicationdata);

            var projektdatendata = application.get('model').projektdaten.objectAt(0);
            var projektdatendataJSON = JSON.stringify(projektdatendata);

            //m0601

            var systemdata = application.get('model').system.objectAt(0);
            var systemdataJSON = JSON.stringify(systemdata);

            var auflagerdata = application.get('model').auflager.objectAt(0);
            var auflagerdataJSON = JSON.stringify(auflagerdata);

            var konstruktiondata = application.get('model').konstruktion.objectAt(0);
            var konstruktiondataJSON = JSON.stringify(konstruktiondata);

            var materialiendata = application.get('model').materialien.objectAt(0);
            var materialiendataJSON = JSON.stringify(materialiendata);

            var einwirkungdata = application.get('model').einwirkung.objectAt(0);
            var einwirkungdataJSON = JSON.stringify(einwirkungdata);

            var nachweisedata = application.get('model').schwingungsnachweis.objectAt(0);
            var nachweisedataJSON = JSON.stringify(nachweisedata);

            var schraubenvorauswahldata = application.get('model').verbindungsmittel.objectAt(0);
            var schraubenvorauswahldataJSON = JSON.stringify(schraubenvorauswahldata);

            var uebergabedaten = $.extend(true, JSON.parse(systemdataJSON), JSON.parse(auflagerdataJSON), JSON.parse(konstruktiondataJSON), JSON.parse(materialiendataJSON), JSON.parse(einwirkungdataJSON), JSON.parse(nachweisedataJSON), JSON.parse(schraubenvorauswahldataJSON));

            //Lastannahmen/Parameter_Strukt.vb

            var timberelement = {
              "Querschnittswerte": {
                "QuerschnittsID": "1",
                "t_Fastener": materialiendata.get('b'),
                "b": materialiendata.get('b'),
                "h": materialiendata.get('h')
              },
              "alphaRAD_KraftFaser": "0,0",
              "alphaRAD_vmFaser": "90,0",
              "betaRAD_vmFurnier": "90,0",
              "vorgebohrt": materialiendata.get('vorgebohrtBeam'),
              "MatKey": materialiendata.get('MatKeyBeam'),
              "FKLKey": materialiendata.get('FKLKeyBeam')
            };

            var interlayerelement = {
              "Querschnittswerte": {
                "QuerschnittsID": "1",
                "t_Fastener": materialiendata.get('e_Beam'),
                "b": materialiendata.get('e_Beam'),
                "h": materialiendata.get('schalung')
              },
              "alphaRAD_KraftFaser": "0,0",
              "alphaRAD_vmFaser": "90,0",
              "betaRAD_vmFurnier": "90,0",
              "vorgebohrt": materialiendata.get('vorgebohrtInterlayer'),
              "MatKey": materialiendata.get('MatKeyInterlayer'),
              "FKLKey": materialiendata.get('FKLKeyInterlayer')
            };

            var concreteelement = {
              "Querschnittswerte": {
                "QuerschnittsID": "1",
                "b": materialiendata.get('e_Beam'),
                "h": materialiendata.get('betonDicke')
              },
              "MatKey": materialiendata.get('MatKeyConcrete'),
              "FKLKey": materialiendata.get('FKLKeyConcrete')
            };

            var einwirkungen = [];

            uebergabedaten.TimberElement = timberelement;
            uebergabedaten.InterlayerElement = interlayerelement;
            uebergabedaten.ConcreteElement = concreteelement;

            console.log(JSON.stringify(uebergabedaten));

            var anzahlLasten = application.get('model').einzelneeinwirkung.content.length;

            console.log("######");
            console.log(anzahlLasten);

            for (var i = 0; i <= anzahlLasten - 1; i++) {
              var last = {
                'LoadProperties': application.get('model').einzelneeinwirkung.objectAt(i),
                'LoadEffect': {
                  'MyID': 0,
                  'MyText': 'foo',
                  'MyKLED': 2,
                  'MyTextKLED': 'feucht',
                  'MyXi0': 1.0,
                  'MyXi1': 2.0,
                  'MyXi2': 3.0,
                  'MyOriginID': 23
                }
              };
              einwirkungen.push(last);
            }

            console.log("!!!");
            console.log(einwirkungen);

            uebergabedaten.LoadList = einwirkungen;

            var JSONdata = JSON.stringify(uebergabedaten);

            if (self.debug) {
              console.log(applicationdataJSON);
              console.log(projektdatendataJSON);
              console.log(JSONdata);
            }

            var vmittel = self.controllerFor('verbindungsmittel');
            vmittel.set('loading', true);

            self.set('loading', true);
            self.transitionToRoute('verbindungsmittel');

            var initialized = applicationdata.get('initialized');

            var server = application.get('server');
            var pfad = application.get('pfad');

            if (!initialized) {

              $.ajax({
                type: "POST",
                url: server + "m0601/DE/initialisiereBerechnung/" + self.controllerFor('supercontroller').erzeugeAufrufzusatz(false),
                //url: "http://136.243.4.143/berechnungsservice/m06/DE/erzeugeSchraubenliste/",
                data: {
                  kennung: applicationdataJSON,
                  // projektdaten: projektdatendataJSON,
                  paras: JSONdata,
                  modul: "m0601",
                  pfad: pfad
                }
              }).done(function (data, statusText, xhr) {

                self.controllerFor('supercontroller').logaufruf("initialisiereBerechnung", xhr.status);

                var cid = data;

                console.log('cid: ' + cid);
                applicationdata.set('Calculation_ID', cid);
                applicationdata.set('initialized', true);

                $.ajax({
                  type: "POST",
                  url: server + "m0601/DE/erzeugeSchraubenliste/" + self.controllerFor('supercontroller').erzeugeAufrufzusatz(false),
                  data: {
                    cid: cid
                  }
                }).done(function (data, statusText, xhr) {

                  self.controllerFor('supercontroller').logaufruf("erzeugeSchraubenliste", xhr.status);

                  console.log(data);

                  if (Number(JSON.parse(data).Item1) === 0) {
                    var liste = JSON.parse(JSON.parse(data).Item2);
                    self.verbindungsmittelliste = liste;
                    var core = treetools.buildTree(liste, true);
                    vmittel.set('schraubenliste', core);
                    vmittel.set('keineSchraubenliste', false);
                  } else if (Number(JSON.parse(data).Item1) === 1) {
                    application.set('verbindungsmittelInvalid', false);
                    application.get('model').x3ddefault.objectAt(0).set('ergebnisGeladen', true);
                    vmittel.set('infoText', JSON.parse(data).Item3);
                    vmittel.set('keineSchraubenliste', true);
                  }

                  vmittel.set('loading', false);
                  vmittel.set('calcid', cid);

                  // applicationdata.set('treeLoaded', true);

                  self.set('loading', false);
                });
              });
            } else {

              var cid = applicationdata.get('Calculation_ID');

              $.ajax({
                type: "POST",
                url: server + "m0601/DE/erzeugeSchraubenliste/" + self.controllerFor('supercontroller').erzeugeAufrufzusatz(false),
                data: {
                  cid: cid
                }
              }).done(function (data, statusText, xhr) {

                self.controllerFor('supercontroller').logaufruf("erzeugeSchraubenliste", xhr.status);

                console.log('DATA ' + data);

                // var liste = JSON.parse(data);
                // self.verbindungsmittelliste = liste;
                // var core = treetools.buildTree(liste, true);

                if (Number(JSON.parse(data).Item1) === 0) {
                  var liste = JSON.parse(JSON.parse(data).Item2);
                  self.verbindungsmittelliste = liste;
                  var core = treetools.buildTree(liste, true);
                  vmittel.set('schraubenliste', core);
                  vmittel.set('keineSchraubenliste', false);
                } else if (Number(JSON.parse(data).Item1) === 1) {
                  application.set('verbindungsmittelInvalid', false);
                  application.get('model').x3ddefault.objectAt(0).set('ergebnisGeladen', true);
                  vmittel.set('infoText', JSON.parse(data).Item3);
                  vmittel.set('keineSchraubenliste', true);
                }

                vmittel.set('schraubenliste', core);
                vmittel.set('loading', false);
                vmittel.set('calcid', cid);

                self.set('loading', false);
              });
            }
          } else {
            self.set('displayErrors', true);
            application.set('lasteinwirkungInvalid', true);
          }
        });
      },

      divWInstIsSelected: function divWInstIsSelected() {
        if (this.get('divWInst') !== "") {
          var valuework = parseFloat(this.get('divWInst').replace(",", "."));
          var valuerounded = valuework.toFixed(1);
          this.set('divWInst', valuerounded);
        }
        document.getElementsByName('divWInst')[0].setSelectionRange(0, this.get('divWInst').length);
      },

      divWFinIsSelected: function divWFinIsSelected() {
        if (this.get('divWFin') !== "") {
          var valuework = parseFloat(this.get('divWFin').replace(",", "."));
          var valuerounded = valuework.toFixed(1);
          this.set('divWFin', valuerounded);
        }
        document.getElementsByName('divWFin')[0].setSelectionRange(0, this.get('divWFin').length);
      },

      divWFinNetIsSelected: function divWFinNetIsSelected() {
        if (this.get('divWFinNet') !== "") {
          var valuework = parseFloat(this.get('divWFinNet').replace(",", "."));
          var valuerounded = valuework.toFixed(1);
          this.set('divWFinNet', valuerounded);
        }
        document.getElementsByName('divWFinNet')[0].setSelectionRange(0, this.get('divWFinNet').length);
      },

      grenzwertVerformungenIsSelected: function grenzwertVerformungenIsSelected() {
        if (this.get('grenzwertVerformungen') !== "") {
          var valuework = parseFloat(this.get('grenzwertVerformungen').replace(",", "."));
          var valuerounded = valuework.toFixed(1);
          this.set('grenzwertVerformungen', valuerounded);
        }
        document.getElementsByName('grenzwertVerformungen')[0].setSelectionRange(0, this.get('grenzwertVerformungen').length);
      },

      iterationenIsSelected: function iterationenIsSelected() {
        if (this.get('iterationen') !== "") {
          var valuework = parseFloat(this.get('iterationen').replace(",", "."));
          var valuerounded = valuework.toFixed(0);
          this.set('iterationen', valuerounded);
        }
        document.getElementsByName('iterationen')[0].setSelectionRange(0, this.get('iterationen').length);
      },

      deckenfeldbreiteIsSelected: function deckenfeldbreiteIsSelected() {
        if (this.get('deckenfeldbreite') !== "") {
          var valuework = parseFloat(this.get('deckenfeldbreite').replace(",", "."));
          var valuerounded = valuework.toFixed(2);
          this.set('deckenfeldbreite', valuerounded);
        }
        document.getElementsByName('deckenfeldbreite')[0].setSelectionRange(0, this.get('deckenfeldbreite').length);
      },

      biegefestigkeitQuerIsSelected: function biegefestigkeitQuerIsSelected() {
        if (this.get('biegefestigkeitQuer') !== "") {
          var valuework = parseFloat(this.get('biegefestigkeitQuer').replace(",", "."));
          var valuerounded = valuework.toFixed(0);
          this.set('biegefestigkeitQuer', valuerounded);
        }
        document.getElementsByName('biegefestigkeitQuer')[0].setSelectionRange(0, this.get('biegefestigkeitQuer').length);
      },

      daempfungsmassQuerIsSelected: function daempfungsmassQuerIsSelected() {
        if (this.get('daempfungsmass') !== "") {
          var valuework = parseFloat(this.get('daempfungsmass').replace(",", "."));
          var valuerounded = valuework.toFixed(3);
          this.set('daempfungsmass', valuerounded);
        }
        document.getElementsByName('daempfungsmass')[0].setSelectionRange(0, this.get('daempfungsmass').length);
      }
    }

  });

});