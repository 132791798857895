define('m0601/controllers/schraubenvorauswahl', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {

    i18n: Ember['default'].inject.service(),

    debug: true,

    plattenstoss: false,
    mindestanzahlDisabled: true,
    plattenstossDisabled: true,
    mindestanzahl: 25,
    ma: 25,

    mindestanzahlContent: [],

    setValues: function setValues(values) {

      var self = this;

      self.set('plattenstoss', values.plattenstoss);
      self.set('mindestanzahl', parseInt(values.maxSchubverbinder));
    },

    watchPlattenstoss: (function () {
      var self = this;
      var schraubenvorauswahldata = self.controllerFor('application').get('model').schraubenvorauswahl.objectAt(0);

      if (self.get('plattenstoss')) {
        self.filterMindestanzahl(0);
        schraubenvorauswahldata.set('plattenstoss', true);
      } else {
        self.filterMindestanzahl(1);
        schraubenvorauswahldata.set('plattenstoss', false);
      }

      schraubenvorauswahldata.set('maxSchubverbinder', self.get('mindestanzahl'));
    }).observes('plattenstoss', 'mindestanzahl'),

    filterMindestanzahl: function filterMindestanzahl(evenUneven) {
      var self = this;

      if (evenUneven == 0) {
        var i = 0;
        var tmp = [];
        var ma = self.get('ma');
        for (i = 2; i <= ma; i = i + 2) {
          tmp.push({
            value: i,
            name: i + "!"
          });
        }

        self.set('mindestanzahlContent', tmp);
      } else if (evenUneven == 1) {
        var i = 0;
        var tmp = [];
        var ma = self.get('ma');
        for (i = 1; i <= ma; i++) {
          tmp.push({
            value: i,
            name: i + "!"
          });
        }

        self.set('mindestanzahlContent', tmp);
      }
    },

    actions: {
      getMindestanzahl: function getMindestanzahl() {
        var self = this;

        self.set('ma', 25);

        var i = 0;
        var tmp = [];

        for (i = 1; i <= self.get('ma'); i++) {
          tmp.push({
            value: i,
            name: i + "!"
          });
        }

        self.set('mindestanzahlContent', tmp);
        console.log(self.mindestanzahlContent);
      },

      proceedInput: function proceedInput(event) {

        var self = this;

        //var ledText = self.ledarray[self.get('led')].name;
        //var nklText = self.nklarray[self.get('nkl')].name;

        var validation = self.get('isValid');

        // if (validation === true) {
        //
        //   var lasteinwirkung = self.store.createRecord('lasteinwirkung', {
        //
        //     KLED_ID: self.get('led'),
        //     LED_Text: ledText,
        //     NKL_ID: self.get('nkl'),
        //     NKL_Text: nklText,
        //     V_d: self.get('bmlast').replace(",", ".")
        //
        //   });
        // }

        self.validate().then(function () {
          // all validations pass
          this.transitionToRoute('verbindungsmittel');
        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          console.log('SCHRAUBENVORAUSWAHL');

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {

            var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            // applicationdata.set('treeLoaded', false);
            // applicationdata.set('initialized', false);
            // applicationdata.set('pdfErstellt', false);
            // applicationdata.set('dxfErstellt', false);
            //
            // application.set('verbindungsmittelInvalid', true);

            self.set('displayErrors', false);
            // application.set('lasteinwirkungInvalid', false);

            //var applicationdata = application.model.objectAt(0);

            //universal
            var applicationdata = application.get('model').application.objectAt(0);
            var applicationdataJSON = JSON.stringify(applicationdata);

            var projektdatendata = application.get('model').projektdaten.objectAt(0);
            var projektdatendataJSON = JSON.stringify(projektdatendata);

            //m0601

            var systemdata = application.get('model').system.objectAt(0);
            var systemdataJSON = JSON.stringify(systemdata);

            var auflagerdata = application.get('model').auflager.objectAt(0);
            var auflagerdataJSON = JSON.stringify(auflagerdata);

            var konstruktiondata = application.get('model').konstruktion.objectAt(0);
            var konstruktiondataJSON = JSON.stringify(konstruktiondata);

            var materialiendata = application.get('model').materialien.objectAt(0);
            var materialiendataJSON = JSON.stringify(materialiendata);

            var einwirkungdata = application.get('model').einwirkung.objectAt(0);
            var einwirkungdataJSON = JSON.stringify(einwirkungdata);

            var nachweisedata = application.get('model').schwingungsnachweis.objectAt(0);
            var nachweisedataJSON = JSON.stringify(nachweisedata);

            var schraubenvorauswahldata = application.get('model').schraubenvorauswahl.objectAt(0);
            var schraubenvorauswahldataJSON = JSON.stringify(schraubenvorauswahldata);

            var uebergabedaten = $.extend(true, JSON.parse(systemdataJSON), JSON.parse(auflagerdataJSON), JSON.parse(konstruktiondataJSON), JSON.parse(materialiendataJSON), JSON.parse(einwirkungdataJSON), JSON.parse(nachweisedataJSON), JSON.parse(schraubenvorauswahldataJSON));

            //Lastannahmen/Parameter_Strukt.vb

            var timberelement = {
              "Querschnittswerte": {
                "b": materialiendata.get('b'),
                "h": materialiendata.get('h')
              },
              "vorgebohrt": false
            };

            var interlayerelement = {
              "Querschnittswerte": {
                "b": materialiendata.get('e_Beam'),
                "h": materialiendata.get('schalung')
              },
              "vorgebohrt": false
            };

            var concreteelement = {
              "Querschnittswerte": {
                "b": materialiendata.get('e_Beam'),
                "h": materialiendata.get('betonDicke')
              }
            };

            var einwirkungen = {
              "load": schraubenvorauswahldata.get('einwirkungen')
            };

            uebergabedaten.TimberElement = timberelement;
            uebergabedaten.InterlayerElement = interlayerelement;
            uebergabedaten.ConcreteElement = concreteelement;
            uebergabedaten.LoadList = einwirkungen;

            console.log(JSON.stringify(uebergabedaten));

            var test = application.get('model').einzelneeinwirkung.content.length;

            for (var i = 1; i <= test; i++) {
              einwirkungen[i] = application.get('model').einzelneeinwirkung.objectAt(i);
            }

            console.log(einwirkungen);

            var JSONdata = JSON.stringify(uebergabedaten);

            if (self.debug) {
              console.log(JSONdata);
            }

            var vmittel = self.controllerFor('verbindungsmittel');
            vmittel.set('loading', true);

            self.set('loading', true);
            self.transitionToRoute('verbindungsmittel');

            var initialized = applicationdata.get('initialized');

            console.log(JSONdata);

            var server = application.get('server');
            var pfad = application.get('pfad');

            if (!initialized) {

              $.ajax({
                type: "POST",
                url: server + "m0601/DE/initialisiereBerechnung/" + self.controllerFor('supercontroller').erzeugeAufrufzusatz(false),
                //url: "http://136.243.4.143/berechnungsservice/m06/DE/erzeugeSchraubenliste/",
                data: {
                  kennung: applicationdataJSON,
                  // projektdaten: projektdatendataJSON,
                  paras: JSONdata,
                  modul: "m0601",
                  pfad: pfad
                }
              }).done(function (data, statusText, xhr) {

                self.controllerFor('supercontroller').logaufruf("initialisiereBerechnung", xhr.status);

                var cid = data;
                console.log('cid: ' + cid);
                applicationdata.set('Calculation_ID', cid);
                applicationdata.set('initialized', true);

                $.ajax({
                  type: "POST",
                  url: server + "m0601/DE/erzeugeSchraubenliste/" + self.controllerFor('supercontroller').erzeugeAufrufzusatz(false),
                  data: {
                    cid: cid
                  }
                }).done(function (data, statusText, xhr) {

                  self.controllerFor('supercontroller').logaufruf("erzeugeSchraubenliste", xhr.status);

                  console.log(data);

                  //var vmittel = self.controllerFor('verbindungsmittel');
                  var liste = JSON.parse(data);
                  self.verbindungsmittelliste = liste;
                  var core = treetools.buildTree(liste, true);
                  vmittel.set('schraubenliste', core);
                  vmittel.set('loading', false);
                  vmittel.set('calcid', cid);

                  applicationdata.set('treeLoaded', true);

                  self.set('loading', false);
                });
              });
            } else {

              var cid = applicationdata.get('Calculation_ID');

              $.ajax({
                type: "POST",
                url: server + "m0601/DE/erzeugeSchraubenliste/" + self.controllerFor('supercontroller').erzeugeAufrufzusatz(false),
                data: {
                  cid: cid
                }
              }).done(function (data, statusText, xhr) {

                self.controllerFor('supercontroller').logaufruf("erzeugeSchraubenliste", xhr.status);

                console.log('DATA ' + data);

                var vmittel = self.controllerFor('verbindungsmittel');
                var liste = JSON.parse(data);
                self.verbindungsmittelliste = liste;
                var core = treetools.buildTree(liste, true);
                vmittel.set('schraubenliste', core);
                vmittel.set('loading', false);
                vmittel.set('calcid', cid);

                applicationdata.set('treeLoaded', true);

                self.set('loading', false);
              });
            }
          } else {
            self.set('displayErrors', true);
            application.set('lasteinwirkungInvalid', true);
          }
        });
      }
    }

  });

});