define('m0601/controllers/konstruktion', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {
    i18n: Ember['default'].inject.service(),

    validations: {
      montageplatz: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 100.0
        }
      }
    },

    ttMontageplatz: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0.0 - 100.0 [cm]";
    }),

    openFromFile: false,

    montageplatz: "",
    deckenkonstruktion: 0,
    hbvSystem: 2,

    deckenkonstruktionGrafik: "assets/images/deckenkonstruktion_",
    deckenkonstruktionGrafikEndung: ".png",
    konGrafik: "assets/images/deckenkonstruktion_0.png",
    konBezeichnung: "Balkendecke",

    konBezeichnungen: {
      0: "balkendecke",
      1: "flaechendecke",
      2: "dippelbaumdecke",
      3: "elementdecke"
    },

    hbvGrafik: "assets/images/hbvSystem_2.png",
    hbvSystemGrafik: "assets/images/hbvSystem_",
    hbvSystemGrafikEndung: ".png",
    hbvBezeichnung: "FT-Nassmontage",

    hbvBezeichnungen: {
      0: "VGSchrauben90",
      1: "VGSchrauben45",
      2: "FTVerbinderNassmontage",
      3: "FTVerbinderFertigteil"
    },

    init: function init() {
      this._super();

      this.setTranslations();
    },

    setTranslations: function setTranslations() {
      this.set('konBezeichnung', this.get('i18n').t("balkendecke").toString());
      this.set('hbvBezeichnung', this.get('i18n').t("FTVerbinderNassmontage").toString());
    },

    setValues: function setValues(values) {

      var self = this;

      console.log('Decken: ' + parseInt(values.VarDecken));
      console.log('setHbvSystem: ' + parseInt(values.VarVerbund));
      console.log('montageplatz: ' + parseInt(values.dxWall));

      self.send('setDeckenkonstruktion', parseInt(values.VarDecken));
      self.send('setHbvSystem', String(values.VarVerbund));
      self.set('montageplatz', values.dxWall);

      self.set('openFromFile', true);
      self.send('validation');
      self.set('openFromFile', false);
    },

    watchNumericalInputs: (function () {

      if (!this.openFromFile) {
        if (this.get('montageplatz').indexOf(",") !== -1) {
          this.set('montageplatz', this.get('montageplatz').replace(",", "."));
        }
      }
    }).observes('montageplatz'),

    watchComboboxes: (function () {
      var self = this;
      var application = self.controllerFor('application');
      application.set('verbindungsmittelInvalid', true);
    }).observes('deckenkonstruktion', 'hbvSystem'),

    actions: {

      setDeckenkonstruktion: function setDeckenkonstruktion(dk) {
        var self = this;
        var konstruktiondata = self.controllerFor('application').get('model').konstruktion.objectAt(0);

        self.set('deckenkonstruktion', dk);
        konstruktiondata.set('VarDecken', dk);
        self.set('konGrafik', self.get('deckenkonstruktionGrafik') + dk + self.get('deckenkonstruktionGrafikEndung'));
        self.set('konBezeichnung', this.get('i18n').t(self.konBezeichnungen[dk]).toString());

        console.log('dk: ');

        var x3d = self.controllerFor('application').get('model').x3ddefault.objectAt(0);
        var bauteile = x3d.get('bauteile');
        var grundmasse = x3d.get('bauteile').findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0);

        var mat = self.controllerFor('materialien');

        switch (String(dk)) {
          case "0":
            grundmasse.set('deckenkonstruktion', 'balken');
            mat.set('breite', "");
            mat.set('bAbstand', "");
            bauteile.findBy('id', 'unterkonstruktion1').get('boxsizes').objectAt(0).set('z', 10);
            bauteile.findBy('id', 'unterkonstruktion2').get('boxsizes').objectAt(0).set('z', 10);
            bauteile.findBy('id', 'beton').get('boxsizes').objectAt(0).set('z', 100 + Number(bauteile.findBy('id', 'unterkonstruktion1').get('boxsizes').objectAt(0).get('z')));
            bauteile.findBy('id', 'schalung').get('boxsizes').objectAt(0).set('z', 100 + Number(bauteile.findBy('id', 'unterkonstruktion1').get('boxsizes').objectAt(0).get('z')));
            bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).set('tiefeUnterkonstruktion', 100);
            mat.set('breiteInputDisabled', false);
            mat.set('abstandInputDisabled', false);
            mat.setUkMaterial();
            break;
          case "1":
            grundmasse.set('deckenkonstruktion', 'holzelement');
            mat.set('breite', "100.0");
            mat.set('bAbstand', "100.0");
            mat.set('breiteInputDisabled', true);
            mat.set('abstandInputDisabled', true);
            mat.setUkMaterial();
            break;
          case "2":
            grundmasse.set('deckenkonstruktion', 'dippelbaum');
            mat.set('breite', "");
            mat.set('bAbstand', "");
            bauteile.findBy('id', 'unterkonstruktion1').get('boxsizes').objectAt(0).set('z', 10);
            bauteile.findBy('id', 'unterkonstruktion2').get('boxsizes').objectAt(0).set('z', 10);
            bauteile.findBy('id', 'beton').get('boxsizes').objectAt(0).set('z', 100 + Number(bauteile.findBy('id', 'unterkonstruktion1').get('boxsizes').objectAt(0).get('z')));
            bauteile.findBy('id', 'schalung').get('boxsizes').objectAt(0).set('z', 100 + Number(bauteile.findBy('id', 'unterkonstruktion1').get('boxsizes').objectAt(0).get('z')));
            mat.set('breiteInputDisabled', false);
            mat.set('abstandInputDisabled', true);
            mat.set('material', 0);
            mat.setUkMaterial();
            break;
          case "3":
            grundmasse.set('deckenkonstruktion', 'elementdecke');
            break;

          default:
        }

        mat.send('validation', mat.get('breite'), { target: { name: "breite" } });
        mat.send('validation', mat.get('bAbstand'), { target: { name: "bAbstand" } });
        // self.controllerFor('materialien').send('validation', -1, {target:{name: "foobar"}});

        if (x3d.get('ergebnisGeladen') === true) {
          self.controllerFor('system').resetGrafikToDefault();
        }
        x3d.set('transformHelper', !x3d.get('transformHelper'));
      },

      setHbvSystem: function setHbvSystem(hbvSys) {
        var self = this;
        var konstruktiondata = self.controllerFor('application').get('model').konstruktion.objectAt(0);

        self.set('hbvSystem', hbvSys);
        konstruktiondata.set('VarVerbund', hbvSys);
        self.set('hbvGrafik', self.get('hbvSystemGrafik') + hbvSys + self.get('hbvSystemGrafikEndung'));
        self.set('hbvBezeichnung', this.get('i18n').t(self.hbvBezeichnungen[hbvSys]).toString());

        var materialien = self.controllerFor('materialien');

        if (hbvSys === 0) {
          materialien.set('schalungSetzbar', true);
        } else {
          materialien.set('schalungSetzbar', false);
        }

        // var vmittel = self.controllerFor('verbindungsmittel');

        var x3d = self.controllerFor('application').get('model').x3ddefault.objectAt(0);
        var grundmasse = x3d.get('bauteile').findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0);

        console.log('hbvSys: ' + hbvSys);
        console.log(self.get('hbvGrafik'));

        console.log(self.get('hbvBezeichnung'));
        console.log(self.hbvBezeichnungen[hbvSys]);

        console.log(hbvSys);

        switch (hbvSys) {
          case "0":
            grundmasse.set('hbvSystem', '90Grad'); // 5,0 - 999,0
            // vmittel.set('plattenstossDisabled', true);
            // vmittel.set('mindestanzahlDisabled', true);

            materialien.set('validations.bDicke.numericality.greaterThanOrEqualTo', 5);
            materialien.set('validations.bDicke.numericality.lessThanOrEqualTo', 999);
            materialien.set('ttBDicke', self.get('i18n').t('wertebereich') + " 5.0 - 999.0 [cm]");

            break;
          case "1":

            console.log('case 1 - hbvSystem', '45Grad');
            grundmasse.set('hbvSystem', '45Grad'); // 5,0 - 999,0
            // vmittel.set('plattenstossDisabled', true);
            // vmittel.set('mindestanzahlDisabled', true);

            materialien.set('validations.bDicke.numericality.greaterThanOrEqualTo', 5);
            materialien.set('validations.bDicke.numericality.lessThanOrEqualTo', 999);
            materialien.set('ttBDicke', self.get('i18n').t('wertebereich') + " 5.0 - 999.0 [cm]");

            break;
          case "2":
            grundmasse.set('hbvSystem', 'ftNass'); // 7,0 - 12,0
            // vmittel.set('plattenstossDisabled', true);
            // vmittel.set('mindestanzahlDisabled', true);

            materialien.set('validations.bDicke.numericality.greaterThanOrEqualTo', 7);
            materialien.set('validations.bDicke.numericality.lessThanOrEqualTo', 12);
            materialien.set('ttBDicke', self.get('i18n').t('wertebereich') + " 7.0 - 12.0 [cm]");

            break;
          case "3":
            grundmasse.set('hbvSystem', 'ftTrocken'); // 7,0 - 12,0
            // vmittel.set('plattenstossDisabled', false);
            // vmittel.set('mindestanzahlDisabled', false);

            materialien.set('validations.bDicke.numericality.greaterThanOrEqualTo', 7);
            materialien.set('validations.bDicke.numericality.lessThanOrEqualTo', 12);
            materialien.set('ttBDicke', self.get('i18n').t('wertebereich') + " 7.0 - 12.0 [cm]");

            break;

          default:
        }

        if (x3d.get('ergebnisGeladen') === true) {
          self.controllerFor('system').resetGrafikToDefault();
        }

        self.controllerFor('materialien').send('validation', -1, { target: { name: "foobar" } });

        x3d.set('transformHelper', !x3d.get('transformHelper'));
      },

      validation: function validation(value, event) {

        var self = this;

        if (!self.openFromFile) {

          this.setX3D(value, event);
        }

        self.validate().then(function () {
          // all validations pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {
            self.set('displayErrors', false);
            application.set('systemInvalid', false);

            var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            applicationdata.set('treeLoaded', false);
            applicationdata.set('initialized', false);
            applicationdata.set('pdfErstellt', false);
            applicationdata.set('dxfErstellt', false);

            var downloadcenter = self.controllerFor('downloadcenter');
            downloadcenter.set('pdfErstellt', false);
            downloadcenter.set('dxfErstellt', false);

            application.set('verbindungsmittelInvalid', true);

            var konstruktiondata = self.controllerFor('application').get('model').konstruktion.objectAt(0);

            konstruktiondata.set('dxWall', parseFloat(self.get('montageplatz')).toFixed(1));
          } else {
            self.set('displayErrors', true);
            application.set('bauteileInvalid', true);
          }
        });
      },

      montageplatzIsSelected: function montageplatzIsSelected() {
        var montageplatzAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('montageplatzAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('montageplatzAktiv', !montageplatzAktiv);
        if (this.get('montageplatz') !== "") {
          var montageplatzwork = parseFloat(this.get('montageplatz').replace(",", "."));
          var montageplatzrounded = montageplatzwork.toFixed(1);
          this.set('montageplatz', montageplatzrounded);
          this.send('validation', montageplatzrounded, { target: { name: "htbreite" } });
        }

        if (montageplatzAktiv === false) {
          document.getElementsByName('montageplatz')[0].setSelectionRange(0, this.get('montageplatz').length);
        }
      }
    },

    setX3D: function setX3D(value, event) {
      var self = this;
      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var self = this;

      application.set('verbindungsmittelInvalid', true);

      switch (event.target.name) {
        case 'montageplatz':
          if (this.errors.montageplatz.length === 0) {
            value = parseFloat(value).toFixed(1);
            bauteile.findBy('id', 'grundkonstruktion').get('grundmasse').objectAt(0).set('montageplatz', value);
            x3d.set('montageplatzEingetragen', true);
          } else {
            x3d.set('montageplatzEingetragen', false);
          }
          break;
      }
      x3d.set('transformHelper', !x3d.get('transformHelper'));
      self.controllerFor('application').zentriertObjekt();
    }

  });

});